import styled from '@emotion/styled'
import { Accordion } from 'app/components/Common/Accordion'
import { Props as ItemProps } from 'app/components/Common/Accordion/item'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Dots } from 'app/components/Common/Dots'
import { Line } from 'app/components/Common/Line'
import React, { memo } from 'react'

export interface Props {
  items: ItemProps[]
  pretitle?: string
  title?: string
}

export const BlockFAQ = memo(function BlockFAQ({
  items,
  pretitle,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper>
        <Left>
          {pretitle ? (
            <FadeInUp>
              <Line />
              <Pretitle>{pretitle}</Pretitle>
            </FadeInUp>
          ) : null}
          {title ? <Title text={title} /> : null}
          <StyledFadeInUp delay={0.1}>
            <img src="/icon-shape-01.svg" alt="shape-01" />
          </StyledFadeInUp>
          <StyledFadeInUp delay={0.15}>
            <img src="/icon-shape-02.svg" alt="shape-02" />
          </StyledFadeInUp>
          <StyledFadeInUp delay={0.2}>
            <img src="/icon-shape-03.svg" alt="shape-03" />
          </StyledFadeInUp>
          <StyledFadeInUp delay={0.25}>
            <img src="/icon-shape-04.svg" alt="shape-04" />
          </StyledFadeInUp>
          <StyledDots width={7} height={6} />
        </Left>
        <Right>{items ? <Accordion items={items} /> : null}</Right>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: calc(10vw + 40px) 0;
  padding: 0 10vw;

  @media (max-width: 1199px) {
    margin: 70px 0 0 0;
    padding: 70px 30px;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 1199px) {
    display: block;
  }
`

const Left = styled.div`
  width: 44%;

  img {
    height: 2.5vw;
    width: auto;
    margin-right: 2.08vw;
  }

  @media (max-width: 1199px) {
    width: 100%;
    position: relative;

    img {
      height: 20px;
      width: auto;
      margin-right: 10px;
    }
  }
`

const Right = styled.div`
  width: 56%;
  margin-top: 3.33vw;

  @media (max-width: 1199px) {
    width: 100%;
    margin-top: 70px;
  }
  @media (max-width: 991px) {
    margin-top: 30px;
  }
`

const Pretitle = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.83vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.69vw;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  @media (max-width: 1439px) {
    font-size: 10px;
    margin-left: 12px;
  }
`

const Title = styled(AnimatedTitle)`
  margin: 2.08vw 0 3.3vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: bold;
  font-size: 4.16vw;
  line-height: 1.133;
  position: relative;
  z-index: 3;

  @media (max-width: 1199px) {
    font-size: 28px;
  }
  @media (max-width: 991px) {
    margin: 20px 0;
  }
`
const StyledFadeInUp = styled(FadeInUp)`
  display: inline-block;
`

const StyledDots = styled(Dots)`
  margin-top: 5.55vw;

  @media (max-width: 1199px) {
    position: absolute;
    right: 30px;
    top: 5px;
    margin-top: 0;
  }
`
